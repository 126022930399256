



















import { i18n } from '@/main';
import { DynamicTranslationService } from '@/services/DynamicTranslationService';
import { CatalogSongsService } from '@/services/CatalogSongsService';
import { Component, Vue } from 'vue-property-decorator'
import SongDetails from './../Shared/SongDetails.vue';


@Component({
  metaInfo: () => ({
    title: DynamicTranslationService.songDetailsTitle
  }), 
  components:{ 
    SongDetails
  }
})
export default class CatalogSongDetail extends Vue {
  store = CatalogSongsService;
  catalogId = this.$route.params.catalogid;
}
